<template>
    <div class="sticky-top" id="header" :class="{'home': isHome}">
        <header id="navbar">
            <nav>
                <router-link :to="{name: 'Home'}"><img class="logo" src="@/assets/images/logo.png" alt="Logo" onclick="goTo('video')"></router-link>
                <div class="burger" @click="toggleMenu" :class="{change: menuOpen}">
                    <div class="bar1"></div>
                    <div class="bar2"></div>
                    <div class="bar3"></div>
                </div>
            </nav>
        </header>
        <div id="menu" :class="{open: menuOpen}">
            <nav>
                <img class="logo" src="@/assets/images/logo.png" alt="Logo" onclick="goTo('video')">
                <div class="burger" @click="toggleMenu" :class="{change: menuOpen}">
                    <div class="bar1"></div>
                    <div class="bar2"></div>
                    <div class="bar3"></div>
                </div>
            </nav>
            <div class="links">
                <router-link :to="{ path: '/', hash: '#desarrollando'}" @click.native="toggleMenu">
                    SERVICIOS
                </router-link>
                <router-link :to="{ path: '/', hash: '#clientes'}" @click.native="toggleMenu">
                    SOBRE NOSOTROS
                </router-link>
                <a href="#contacto" data-id="contact" @click="toggleMenu">CONTACTO</a>
                <a href="https://open.spotify.com/show/1DcAOIaBRD0oLZdwXEGvmU?si=6tSmAd7eR_y1vFvjK3Nhew&utm_source=copy-link&dl_branch=1" target="_blank" data-id="podcast" @click="toggleMenu">PODCAST</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Menu",
    data() {
        return {
            menuOpen: false,
            scrollToTop: false,
            lastScrollTop: 0,
            scrollToNavbar: false,
        }
    },
    computed: {
      isHome() {
          return this.$route.name === 'Home'
      }
    },
    methods: {
        toggleMenu() {
            this.menuOpen = !this.menuOpen
        }
    },
    mounted() {
        window.addEventListener("scroll", () => {
            const currentScroll = window.pageYOffset;
            if(currentScroll > document.querySelector('#video').offsetHeight){
                document.querySelector('#header').classList.add('show')
            }else{
                document.querySelector('#header').classList.remove('show')
            }
        });
    }

}
</script>

<style scoped lang="scss">
@-webkit-keyframes scale {
    0% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        margin-top: 0;
    }

    100% {
        -webkit-transform: scale(2) rotate(360deg);
        -ms-transform: scale(2) rotate(360deg);
        transform: scale(2) rotate(360deg);
        margin-top: 50px;
    }
}

@-webkit-keyframes scale_out {
    0% {
        -webkit-transform: scale(2) rotate(360deg);
        -ms-transform: scale(2) rotate(360deg);
        transform: scale(2) rotate(360deg);
        margin-top: 50px;

    }

    100% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        margin-top: 0;
    }
}

#header {
    transition: all .3s ease-in;
    height: 100px;

    &.home{
        visibility: hidden;
        opacity: 0;
    }

    &.show{
        visibility: visible;
        opacity: 1;
    }

    #navbar {
        background-color: black;
        color: white;
        height: 100px;
        overflow: hidden;
        position: sticky;
        top: 0;
        text-align: center;
        width: 100%;
    }

    .logo{
        max-width: 190px;
        transition: .3s all ease-in-out;
        width: 100%;
    }
}

nav {
    display: flex;
    padding: 20px;
    justify-content: space-between;
    margin: auto;
    width: 90%;
}

#menu {
    display: block;
    width: 100%;
    height: 100vh;
    opacity: 0;
    visibility: hidden;
    transition: visibility .5s, opacity .5s linear;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: black;
    z-index: 99999;

    .links {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        padding: 2% 0 20%;

        a {
            color: white;
            font-size: 2rem;
            font-weight: bold;
            margin: 50px;
            text-decoration: none;
            text-align: center;
        }
    }

    .logo{
        -webkit-animation: scale_out .5s ease-in-out;
        animation-fill-mode:forwards; /*when the spec is finished*/
    }

    &.open {
        opacity: 1;
        visibility: visible;

        .logo{
            margin-top: 50px;
            -webkit-animation: scale .5s ease-in-out;
            animation-fill-mode:forwards; /*when the spec is finished*/
        }
    }
}

.burger {
    cursor: pointer;
    padding: 10px;
}

.bar1, .bar2, .bar3 {
    width: 35px;
    height: 5px;
    background-color: #FFFFFF;
    margin: 6px 0;
    transition: 0.4s;
}

.change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {
    opacity: 0;
}

.change .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
}

@media screen and (max-width: 600px){
    @-webkit-keyframes scale {
        0% {
            -webkit-transform: scale(1);
            -ms-transform: scale(1);
            transform: scale(1);
            margin-top: 0;
        }

        100% {
            -webkit-transform: scale(1.3) rotate(360deg);
            -ms-transform: scale(1.3) rotate(360deg);
            transform: scale(1.3) rotate(360deg);
            margin-top: 30px;
        }
    }

    #menu{
        .links{
            height: 70%;
            padding: 20% 0;
            a{
                font-size: 1.5rem;
                margin: 20px 0;
            }
        }
    }
}
</style>