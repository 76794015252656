<template>
    <section id="video" class="p-0">
        <h2 class="phrase">Desarrollando sueños</h2>
        <video preload="auto" playsinline>
            <source src="@/assets/videos/ch-23.mp4" type="video/mp4">
        </video>
        <div class="controls">
            <div class="enable__volume" @click="toggleVolume" v-show="mutedButton" :title="`${volume*100}%`">
                {{ volume ? 'Apagar' : 'Activa' }} sonido
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-volume-up" viewBox="0 0 16 16" v-show="volume === 1">
                    <path
                        d="M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z"/>
                    <path
                        d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z"/>
                    <path
                        d="M10.025 8a4.486 4.486 0 0 1-1.318 3.182L8 10.475A3.489 3.489 0 0 0 9.025 8c0-.966-.392-1.841-1.025-2.475l.707-.707A4.486 4.486 0 0 1 10.025 8zM7 4a.5.5 0 0 0-.812-.39L3.825 5.5H1.5A.5.5 0 0 0 1 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 7 12V4zM4.312 6.39 6 5.04v5.92L4.312 9.61A.5.5 0 0 0 4 9.5H2v-3h2a.5.5 0 0 0 .312-.11z"/>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-volume-down" viewBox="0 0 16 16" v-show="volume === .5">
                    <path
                        d="M9 4a.5.5 0 0 0-.812-.39L5.825 5.5H3.5A.5.5 0 0 0 3 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 9 12V4zM6.312 6.39 8 5.04v5.92L6.312 9.61A.5.5 0 0 0 6 9.5H4v-3h2a.5.5 0 0 0 .312-.11zM12.025 8a4.486 4.486 0 0 1-1.318 3.182L10 10.475A3.489 3.489 0 0 0 11.025 8 3.49 3.49 0 0 0 10 5.525l.707-.707A4.486 4.486 0 0 1 12.025 8z"/>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-volume-mute" viewBox="0 0 16 16" v-show="volume === 0">
                    <path
                        d="M6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06zM6 5.04 4.312 6.39A.5.5 0 0 1 4 6.5H2v3h2a.5.5 0 0 1 .312.11L6 10.96V5.04zm7.854.606a.5.5 0 0 1 0 .708L12.207 8l1.647 1.646a.5.5 0 0 1-.708.708L11.5 8.707l-1.646 1.647a.5.5 0 0 1-.708-.708L10.793 8 9.146 6.354a.5.5 0 1 1 .708-.708L11.5 7.293l1.646-1.647a.5.5 0 0 1 .708 0z"/>
                </svg>
            </div>
        </div>

        <div class="mouse__scroll" @click="goToNavbar">
            <div>
                <span class="m_scroll_arrows uno"></span>
                <span class="m_scroll_arrows dos"></span>
                <span class="m_scroll_arrows tres"></span>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Video",
    data() {
        return {
            interval: 0,
            muted: true,
            mutedButton: false,
            playButton: false,
            paused: true,
            scrollToNavbar: false,
            scrollToTop: false,
            timeout: 0,
            volume: 0,
            lastScrollTop: 0
        }
    },
    watch: {
        volume(newValue) {
            document.querySelector('#video video').volume = newValue
        }
    },
    methods: {
        goToNavbar() {
            window.scrollTo(0, document.getElementById('header').offsetTop + 50)
        },
        toggleVolume() {
            this.volume === 1 ? this.volume = 0 : this.volume = this.volume + 1
        },
        toggleVideo() {
            if (document.querySelector('#video video').paused) {
                document.querySelector('#video video').play()
                this.paused = false
            } else {
                document.querySelector('#video video').pause()
                this.paused = true
            }
        },
        updateCurrentTime() {
            clearInterval(this.interval)
            this.interval = setInterval(() => {
                let currentTime = document.querySelector('#video video').currentTime
                let duration = document.querySelector('#video video').duration

                if (currentTime === duration) {
                    clearInterval(this.interval)
                    this.goToNavbar()
                    document.querySelector('#video video').currentTime = 0
                    this.playButton = true
                }
            }, 500)
        },
        playVideo() {
            document.querySelector('#video video').classList.add('play')
            document.querySelector('#video video').play()
            this.paused = false
            this.mutedButton = true
            document.querySelector('#video .phrase').classList.add('d-none')
            this.updateCurrentTime()
        },
        stopVideo() {
            document.querySelector('#video .phrase') ? document.querySelector('#video .phrase').classList.add('d-none') : ''
            document.querySelector('#video video') ? document.querySelector('#video video').classList.add('play') : ''
            this.playButton = true
            this.mutedButton = true
            document.querySelector('#video video').pause()
            this.paused = true
            clearTimeout(this.timeout)
            clearInterval(this.interval)
        }
    },
    mounted() {
        document.querySelector('#video video').volume = this.volume

        if (!this.$route.hash) {
            this.timeout = setTimeout(() => {
                this.playVideo()
            }, 3000)
        } else {
            document.querySelector('#video video').classList.add('play')
            document.querySelector('#video .phrase').classList.add('d-none')
            this.mutedButton = true
            this.playButton = true
        }

        window.addEventListener("scroll", () => {
            let st = window.pageYOffset || document.documentElement.scrollTop

            if (st > this.lastScrollTop) {
                if (st > 10 && !this.scrollToNavbar) {
                    /*window.scrollTo(st, document.getElementById('header').offsetTop)*/
                    this.stopVideo()
                    this.scrollToNavbar = true
                    setTimeout(() => {
                        this.scrollToTop = false
                    }, 500)
                }
            } else {
                if (st < document.getElementById('header').offsetTop && !this.scrollToTop) {
                    this.scrollToTop = true
                    /*window.scrollTo(st, 0)*/
                    this.playVideo()
                    setTimeout(() => {
                        this.scrollToNavbar = false
                    }, 500)
                }
            }
            this.lastScrollTop = st <= 0 ? 0 : st
        }, false);
    },
}
</script>

<style scoped lang="scss">
#video {
    align-items: center;
    background: #000000;
    display: flex;
    height: 100vh;
    position: relative;
    width: 100%;
    z-index: 999;

    video {
        display: none;
        width: 100%;

        &.play {
            display: block;
        }
    }

    .phrase {
        color: white;
        font-size: 50px;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        width: 100%;
        -webkit-animation: scale-phrase 2s ease-in-out;
        animation-delay: 1000ms;
    }
}

@-webkit-keyframes scale-phrase {
    0% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }

    90% {
        -webkit-transform: scale(30);
        -ms-transform: scale(30);
        transform: scale(30);
    }

    100% {
        -webkit-transform: scale(30);
        -ms-transform: scale(30);
        transform: scale(30);
        opacity: 0;
    }
}

.mouse__scroll {
    cursor: pointer;
    display: block;
    width: 24px;
    height: 100px;
    margin: 125px auto 0;
    position: absolute;
    bottom: 0;
    left: 50%;
    right: 50%;
}

.controls {
    cursor: pointer;
    display: flex;
    position: absolute;
    right: 40px;
    bottom: 40px;

    color: white;
    font-weight: bold;

    svg {
        width: 50px;
        height: 50px;
        color: white;
    }
}

.m_scroll_arrows {
    display: block;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    margin: 0 0 3px 4px;
    width: 16px;
    height: 16px;
}

.uno {
    margin-top: 1px;
}

.uno, .dos, .tres {
    -webkit-animation: mouse-scroll 1s infinite;
    -moz-animation: mouse-scroll 1s infinite;
    animation: mouse-scroll 1s infinite;
}

.uno {
    -webkit-animation-delay: .1s;
    -moz-animation-delay: .1s;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
}

.dos {
    -webkit-animation-delay: .2s;
    -moz-animation-delay: .2s;
    -webkit-animation-direction: alternate;
    animation-delay: .2s;
    animation-direction: alternate;
    margin-top: -6px;
}

.tres {
    -webkit-animation-delay: .3s;
    -moz-animation-delay: .3s;
    -webkit-animation-direction: alternate;
    animation-delay: .3s;
    animation-direction: alternate;
    margin-top: -6px;
}

.wheel {
    display: block;
    margin: 5px auto;
    background: white;
    position: relative;
    height: 4px;
    width: 4px;
    border: 2px solid #fff;
    -webkit-border-radius: 8px;
    border-radius: 8px;
}

.wheel {
    -webkit-animation: mouse-wheel 0.6s linear infinite;
    -moz-animation: mouse-wheel 0.6s linear infinite;
    animation: mouse-wheel 0.6s linear infinite;
}

@-webkit-keyframes mouse-wheel {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(6px);
        -ms-transform: translateY(6px);
        transform: translateY(6px);
    }
}

@-moz-keyframes mouse-wheel {
    0% {
        top: 1px;
    }
    25% {
        top: 2px;
    }
    50% {
        top: 3px;
    }
    75% {
        top: 2px;
    }
    100% {
        top: 1px;
    }
}

@-o-keyframes mouse-wheel {
    0% {
        top: 1px;
    }
    25% {
        top: 2px;
    }
    50% {
        top: 3px;
    }
    75% {
        top: 2px;
    }
    100% {
        top: 1px;
    }
}

@keyframes mouse-wheel {
    0% {
        top: 1px;
    }
    25% {
        top: 2px;
    }
    50% {
        top: 3px;
    }
    75% {
        top: 2px;
    }
    100% {
        top: 1px;
    }
}

@-webkit-keyframes mouse-scroll {
    0% {
        opacity: 0;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes mouse-scroll {
    0% {
        opacity: 0;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes mouse-scroll {
    0% {
        opacity: 0;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

@keyframes mouse-scroll {
    0% {
        opacity: 0;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

@media screen and (max-width: 600px){
    .controls{
        right: 20px;
        bottom: 5px;
    }
}

</style>