<template>
    <div>
        <div class="container-fluid">
            <section id="calidad">
                <Title text="DALE UN SALTO DE CALIDAD"></Title>
                <div class="container">
                    <section class="row" id="accordion">
                        <div class="part col-12 col-md-4"
                             :class="{'selected': selectedPart === 1, 'not__selected': selectedPart !== 1}"
                             @click="expandAccordion(1)">
                            <div :style="background('streaming/lleva-tu-marca-a-otro-nivel.gif')" class="image"></div>
                            <div class="description">
                                <h3>LLEVA TU MARCA A OTRO NIVEL</h3>
                                <ul>
                                    <li>Dirección creativa</li>
                                    <li>Creación de identidad</li>
                                    <li>Manual de marca</li>
                                    <li>Branding</li>
                                    <li>Análisis del mercado</li>
                                    <li>Target</li>
                                    <li>Plan de acción</li>
                                    <li>Testing</li>
                                    <li>Lanzamiento final</li>
                                    <li>Mantenimiento, mejoras y evolución</li>
                                </ul>
                            </div>
                        </div>
                        <div class="part col-12 col-md-4"
                             :class="{'selected': selectedPart === 2, 'not__selected': selectedPart !== 2}"
                             @click="expandAccordion(2)">
                            <div
                                :style="background('streaming/dale-lo-mejor-a-tus-seguidores.gif')"
                                class="image"></div>
                            <div class="description">
                                <h3>DALE LO MEJOR A TUS SEGUIDORES</h3>
                                <ul>
                                    <li>Estudio del streamer y su comunidad</li>
                                    <li>Plan de comunicación con metas definidas</li>
                                    <li>Testeo del mercado y de tus seguidores</li>
                                    <li> Ejecución del plan</li>
                                    <li>Mantenimiento, mejoras y evolución</li>
                                </ul>
                            </div>
                        </div>
                        <div class="part col-12 col-md-4"
                             :class="{'selected': selectedPart === 3, 'not__selected': selectedPart !== 3}"
                             @click="expandAccordion(3)">
                            <div :style="background('streaming/generamos-nuevos-ingresos.gif')" class="image"></div>
                            <div class="description">
                                <h3>GENERAMOS NUEVOS INGRESOS</h3>
                                <ul>
                                    <li>Auditoria de tu marca y tus Relaciones Públicas
                                    <li>Monetización y Sponsors</li>
                                    <li>Generación de un plan comercial integral</li>
                                    <li>Búsqueda de nuevos ingresos</li>
                                    <li>Ejecución del plan comercial</li>
                                    <li>Mantenimiento, mejoras y evolución</li>
                                </ul>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </div>

        <div class="container-fluid">
            <section id="trabajos">
                <Title text="DREAM DEVELOPERS"></Title>
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <div class="display">
<!--
                                <video loop preload="auto" @click="toggleVideo($event)">
                                    <source src="@/assets/videos/salto-de-calidad-a-tu-canal.mp4" type="video/mp4">
                                </video>
-->
                                <VideoSection :src="videoSrc"></VideoSection>
                            </div>

                            <!--                            <section class="buttons">-->
                            <!--                                <button>LOGO</button>-->
                            <!--                                <button>MASCOT</button>-->
                            <!--                                <button>FACECAM</button>-->
                            <!--                                <button>ALERTAS</button>-->
                            <!--                                <button>HEADER</button>-->
                            <!--                                <button>OFFLINE</button>-->
                            <!--                                <button>PANELES</button>-->
                            <!--                                <button>CARRUSELL DE SPONSORS</button>-->
                            <!--                            </section>-->
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <ReturnButton></ReturnButton>
    </div>
</template>

<script>
import Title from "../components/Title";
import VideoSection from "../components/VideoSection";
import ReturnButton from "../components/ReturnButton";

export default {
    components: {
        Title,
        VideoSection,
        ReturnButton
    },
    data() {
        return {
            selectedPart: null
        }
    },
    computed: {
        videoSrc() {
            return require('@/assets/videos/salto-de-calidad-a-tu-canal.mp4')
        }
    },
    methods: {
        background(name) {
            return `background-image: url(${require('../assets/images/' + name)})`
        },
        toggleVideo(event) {
            const video = event.target
            if (video.paused) {
                video.play()
            } else {
                video.pause()
            }
            video.classList.toggle('play')
        },
        expandAccordion(part) {
            this.selectedPart === part ? this.selectedPart = null : this.selectedPart = part
        }
    }
}
</script>

<style scoped lang="scss">

#accordion {
    overflow: hidden;
    max-height: 600px;

    .part {
        cursor: pointer;
        overflow: hidden;
        padding: 0;
        position: relative;

        .image {
            min-height: 600px;
            width: 100%;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            position: relative;
            transition: .3s max-height ease-in;
        }

        .description {
            background: black;
            color: white;
            display: flex;
            flex-direction: column;
            padding: 10px 20px;
            overflow: hidden;
            position: absolute;
            right: 0;
            top: 0;
            left: 0;
            width: 100%;
            transition: .3s max-height ease-in;

            h3{
                color: white;
                font-weight: bold;
                font-size: 2rem;
                margin: 0;
            }

            p, ul {
                margin-top: 50px;
                display: none;
            }
        }

        &.not__selected {
            &:hover {
                filter: grayscale(30%);

                .description {
                    visibility: initial;
                    border-left: 5px solid #1ff900;
                    border-right: 5px solid #1ff900;
                    border-top: 5px solid #1ff900;
                }

                .image {
                    &:before {
                        border: 5px solid #1ff900;
                    }
                }
            }

            .description {
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 5px solid transparent;
                /*visibility: hidden;*/
            }

            .image {
                &:before {
                    border: 5px solid transparent;
                    content: ' ';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    height: 100%;
                    width: 100%;
                }
            }

            filter: grayscale(100%);
            transition: filter .2s ease-in;
        }

        &.selected {
            .description {
                background: rgba(0, 0, 0, .8);
                border: 5px solid transparent;
                max-height: 1000px;
                height: 100%;

                ul{
                    display: block;
                }
            }
        }
    }
}

.display {
    background-size: 100%;
    padding: .5% .5% 50px;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: auto;
    width: 70%;

    video {
        cursor: pointer;
        margin: auto;
        height: 100%;
        width: 100%;
        transition: .3s all ease-in-out;
    }

    img {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        left: 0;
    }
}

#trabajos {
    .buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;

        button {
            background: rgb(230, 33, 133);
            background: linear-gradient(90deg, rgba(230, 33, 133, 1) 0%, rgba(247, 170, 1, 1) 100%);
            border: none;
            border-radius: 10px;
            color: white;
            font-family: 'Montserrat', sans-serif;
            margin: 20px 5px;
            font-weight: bold;
            padding: 10px 20px;
            transition: .3s all ease-in-out;

            &:hover {
                box-shadow: 0 0 10px #6E2884;
            }
        }
    }
}
#video {
    height: unset;
}
iframe {
    min-height: 700px;
}

@media screen and (max-width: 600px){
    #accordion{
        max-height: unset;
    }

    .display{
        width: 100%;
    }

    #accordion .part .image{
        min-height: 400px;
    }
}
</style>