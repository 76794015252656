import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import Streaming from '../views/Streaming'
import Software from "../views/Software";
import Campanas from "../views/Campanas";
import Identidad from "../views/Identidad";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/streaming',
    name: 'Streaming',
    component: Streaming
  },
  {
    path: '/software',
    name: 'Software',
    component: Software
  },
  {
    path: '/campanas',
    name: 'Campanas',
    component: Campanas
  },
  {
    path: '/identidad',
    name: 'Identidad',
    component: Identidad
  }
]

/*
1- /gracias-campañas-y-analitycs
2- /gracias-desarrolla-tu-marca
3- /gracias-software-factory
4- /gracias-ch-streaming
*/

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.name === 'Home'){
    window.scrollTo(0, window.innerHeight + 20)
  }else{
    window.scrollTo(0, 0)
  }
  next()
})

export default router
