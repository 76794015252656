<template>
    <footer class="container-fluid">
        <div class="row">
            <div class="col col-md-5 bg-black text-white">
                <div class="redes">
                    <a target="_blank" href="https://www.instagram.com/comuniquemoshoy/"><img
                        src="@/assets/images/instagram.png" alt="Instagram"></a>
                    <a target="_blank" href="https://www.facebook.com/Comuniquemoshoy/?ref=settings"><img
                        src="@/assets/images/facebook.png" alt="Facebook"></a>
                    <a target="_blank" href="https://www.linkedin.com/company/37895779/"><img
                        src="@/assets/images/linkedin.png" alt="Linkedin"></a>
                    <a target="_blank" href="https://twitter.com/ComuniquemosHoy"><img
                        src="@/assets/images/twitter (1).png" alt="Twitter"></a>
                    <a target="_blank" href="https://www.tiktok.com/@comuniquemoshoy"><img
                        src="@/assets/images/tik-tok (1).png" alt="TikTok"></a>
                    <a target="_blank" href="https://www.twitch.tv/ChStreaming"><img
                        src="@/assets/images/twitch (2).png" alt="Twitch"></a>
                    <a target="_blank" href="https://www.youtube.com/channel/UCp2grAZoFG_CPtQqfrAEpNA"><img
                        src="@/assets/images/youtube (1).png" alt="Youtube"></a>
                </div>
                <p><a href="mailto:marceloeduardo.arrabal@4latam.com">marceloeduardo.arrabal@4latam.com</a></p>
                <p><a href="tel:+529988445400">+52 998 844 5400</a></p>
                <p>2023 - Copyright</p>
                <div class="logo">
                    <img src="@/assets/images/logo.png" alt="Comuniquemos Hoy">
                </div>
                <p>Perteneciente al Holding de <img src="@/assets/images/logo4Latam.png" alt=""></p>
                <p>Jujuy 2156, Buenos Aires, Argentina</p>
            </div>
            <div class="col col-md-7 text-black p-4" id="contacto">
                <h3 class="text-center">Da un salto de calidad</h3>
                <form @submit.prevent="sendForm" v-show="!successMessage">
                    <div class="form-group">
                        <label>Nombre *</label>
                        <input type="text" name="name" class="form-control" required v-model="form.name">
                    </div>
                    <div class="form-group">
                        <label>Email *</label>
                        <input type="email" name="email" class="form-control" required v-model="form.email">
                    </div>
                    <div class="form-group">
                        <label>Teléfono *</label>
                        <input type="tel" name="phone" class="form-control" required v-model="form.phone">
                    </div>
                    <div class="form-group">
                        <label>Mensaje *</label>
                        <textarea class="form-control" required v-model="form.message"></textarea>
                    </div>
                    <div class="form-group pt-2">
                        <small :class="{'text-danger': !successMessage}">{{ sendMessage }}</small>
                    </div>
                    <div class="form-group">
                        <button>Enviar</button>
                    </div>
                </form>
                <div class="form-success" v-show="successMessage">
                    <p>Gracias por ponerte en contacto con nosotros, a la brevedad un asesor comercial se pondrá en
                        contacto contigo.</p>
                    <p>Haz click aquí y visita nuestros Canales</p>
                    <div class="redes">
                        <a target="_blank" href="https://www.instagram.com/comuniquemoshoy/"><img
                            src="@/assets/images/instagram.png" alt="Instagram"></a>
                        <a target="_blank" href="https://www.facebook.com/Comuniquemoshoy/?ref=settings"><img
                            src="@/assets/images/facebook.png" alt="Facebook"></a>
                        <a target="_blank" href="https://www.linkedin.com/company/37895779/"><img
                            src="@/assets/images/linkedin.png" alt="Linkedin"></a>
                        <a target="_blank" href="https://twitter.com/ComuniquemosHoy"><img
                            src="@/assets/images/twitter (1).png" alt="Twitter"></a>
                        <a target="_blank" href="https://www.tiktok.com/@comuniquemoshoy"><img
                            src="@/assets/images/tik-tok (1).png" alt="TikTok"></a>
                        <a target="_blank" href="https://www.twitch.tv/ChStreaming"><img
                            src="@/assets/images/twitch (2).png" alt="Twitch"></a>
                        <a target="_blank" href="https://www.youtube.com/channel/UCp2grAZoFG_CPtQqfrAEpNA"><img
                            src="@/assets/images/youtube (1).png" alt="Youtube"></a>
                    </div>
                </div>
            </div>
        </div>
        <a href="https://api.whatsapp.com/send?phone=529988445400&text=Necesito%20comunicarme%20con%20un%20ejecutivo%20de cuentas%20por%20el%20proyecto%20"
           target="_blank" class="whatsapp__button"><img src="@/assets/images/whatsapp.svg" alt=""></a>
    </footer>
</template>

<script>
import axios from 'axios'

export default {
    name: "Footer",
    data() {
        return {
            form: {},
            successMessage: false,
            sendMessage: ''
        }
    },
    methods: {
        async sendForm() {
            this.form.route = this.$route.name
            await axios.get('https://www.comuniquemoshoy.com/contact/send-message.php?' + new URLSearchParams(this.form), {
                headers: {"Access-Control-Allow-Origin": "*"}
            })
                .then(res => {
                    if (res.data === 'message-sent') {
                        this.form = {}
                        this.sendMessage = 'Mensaje enviado con éxito'
                        this.successMessage = true
                        this.$gtag.event('contacto', {
                            'event_category': `Contacto desde ${this.$route.name}`,
                            'value': 1
                        })
                    } else {
                        this.form = {}
                        this.sendMessage = 'Hubo un problema el enviar el mensaje'
                        this.successMessage = false
                    }
                })
                .catch(() => {
                    this.sendMessage = 'Hubo un problema el enviar el mensaje'
                    this.successMessage = false
                })
        }
    }
}
</script>

<style scoped lang="scss">
footer {
    .whatsapp__button {
        bottom: 40px;
        display: block;
        position: fixed;
        right: 40px;
        width: 80px;
        transition: all .2s ease-in;
        z-index: 99;

        img {
            width: 100%;
        }

        &:hover {
            img {
                filter: drop-shadow(0 0 0.75rem #4AC959);
            }
        }
    }

    .row {
        > .col {
            padding: 100px;
            justify-content: space-between;
        }
    }

    a {
        color: white;
        text-decoration: none;
    }

    input, textarea {
        border-radius: 5px;
        margin-top: 50px;
    }

    .logo {
        padding-bottom: 30px;
    }
    .logo img{max-width:100%;}
    .redes {
        padding-bottom: 30px;

        a {
            display: inline-block;
            margin: 10px 0;
        }

        img {
            margin-right: 40px;
            height: 40px;
        }
    }

    form {
        .form-group {
            position: relative;

            label {
                color: gray;
                font-weight: bold;
                top: 10px;
                left: 20px;
                position: absolute;
            }

            input, textarea {
                padding: 40px 20px 10px;
            }

            button {
                background-color: black;
                border-radius: 5px;
                border: none;
                color: #ffffff;
                margin-top: 30px;
                text-transform: uppercase;
                padding: 10px;
                width: 100%;
            }
        }
    }

    .form-success {
        padding: 100px 0;

        p {
            text-align: center;
        }

        .redes {
            display: flex;
            justify-content: center;
            margin: 50px 0;
            width: 100%;

            img {
                filter: brightness(0);
            }
        }
    }
}

@media screen and (max-width: 769px) {
    footer {
        .row {
            > .col {
                padding: 20px;
                justify-content: space-between;
            }
        }

        .redes {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            a {
                text-align: center;

                img {
                    margin: 0 20px;
                }
            }
        }

        p {
            text-align: center;
        }

        .logo {
            text-align: center;
        }

        .whatsapp__button {
            height: 40px;
            width: 40px;
            bottom: 10px;
            right: 10px;
        }
    }
}
</style>