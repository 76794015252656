<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col">
                    <section id="desarrollando">
                        <!--                        <Title text="DESARROLLANDO SUEÑOS"></Title>-->
                        <div class="container">
                            <div class="content">
                                <div class="row">
                                    <div class="service col">
                                        <router-link :to="{name: 'Campanas'}">
                                            <figure @mouseover="services.campanias.hovered = true"
                                                    @mouseleave="services.campanias.hovered = false">
                                                <img :src="services.campanias.hover" alt="CAMPAÑAS Y ANALYTICS"
                                                     v-show="services.campanias.hovered">
                                                <img :src="services.campanias.original" alt="CAMPAÑAS Y ANALYTICS"
                                                     v-show="!services.campanias.hovered">
                                                <figcaption>
                                                    CAMPAÑAS Y ANALYTICS
                                                </figcaption>
                                            </figure>
                                        </router-link>
                                    </div>
                                    <div class="service col">
                                        <router-link :to="{name: 'Identidad'}">
                                            <figure @mouseover="services.identidad.hovered = true"
                                                    @mouseleave="services.identidad.hovered = false">
                                                <img :src="services.identidad.hover" alt="POTENCIA TU IDENTIDAD"
                                                     v-show="services.identidad.hovered">
                                                <img :src="services.identidad.original" alt="POTENCIA TU IDENTIDAD"
                                                     v-show="!services.identidad.hovered">
                                                <figcaption>
                                                    POTENCIA TU IDENTIDAD
                                                </figcaption>
                                            </figure>
                                        </router-link>
                                    </div>
                                    <div class="service col">
                                        <router-link :to="{name: 'Software'}">
                                            <figure @mouseover="services.software.hovered = true"
                                                    @mouseleave="services.software.hovered = false">
                                                <img :src="services.software.hover" alt="DESARROLLA TU SOFTWARE"
                                                     v-show="services.software.hovered">
                                                <img :src="services.software.original" alt="DESARROLLA TU SOFTWARE"
                                                     v-show="!services.software.hovered">
                                                <figcaption>
                                                    DESARROLLA TU SOFTWARE
                                                </figcaption>
                                            </figure>
                                        </router-link>
                                    </div>
                                    <div class="service col stream">
                                        <router-link :to="{name: 'Streaming'}">
                                            <figure class="ch__streaming">
                                                <img src="@/assets/images/chStreaming1.gif" alt="CH STREAMING">
                                                <figcaption>
                                                    CH <br>STREAMING
                                                </figcaption>
                                            </figure>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="nosotros">
                        <Title text="SOBRE NOSOTROS"></Title>
                    </section>
                </div>
            </div>
        </div>
        <div class="container-fluid" id="clientes">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <p>Si al Talento le sumas metodología y disciplina serás Imparable.</p>
                        <p>Nuestra principal meta es ser puente entre tus Objetivos y tus logros haciendo realidad tus
                            Sueños.</p>
                        <p>La Realidad es la suma de tantas variables que tienden al infinito, si experimentas terrenos
                            no
                            conocidos sin tener un equipo de expertos, <br>estás cometiendo un error que te puede llevar
                            al
                            fracaso.</p>
                        <p>Dispone de un equipo de trabajo de alto rendimiento y da el Salto de Calidad que estás
                            buscando.</p>
                        <p>“Somos lo que hacemos repetidamente. La Excelencia, entonces, no es un acto; es un hábito.”
                            Aristóteles.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="clientes">
                        <CarrouselClientes :images="clientes" auto seconds="3"></CarrouselClientes>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid" id="metricas" @mouseenter="updateMetricas = true">
            <div class="row">
                <div class="col-6 col-md-3" v-for="(metrica, key) in metricas" :key="key">
                    <Metrica :metrica="metrica" :update="updateMetricas"></Metrica>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Title from "../components/Title";
import CarrouselClientes from "../components/CarrouselClientes";
import Metrica from "../components/Metrica";

export default {
    name: 'Home',
    components: {
        Title,
        CarrouselClientes,
        Metrica
    },
    data() {
        return {
            services: {
                campanias: {
                    original: require('../assets/images/campania-y-analytics.jpg'),
                    hover: require('../assets/images/campania-y-analytics.gif'),
                    hovered: this.isMobile()
                },
                identidad: {
                    original: require('../assets/images/desarrolla-tu-identidad-min.jpg'),
                    hover: require('../assets/images/desarrolla-tu-identidad-min.gif'),
                    hovered: this.isMobile()
                },
                software: {
                    original: require('../assets/images/software-min.jpg'),
                    hover: require('../assets/images/software-min.gif'),
                    hovered: this.isMobile()
                },
            },
            metricas: [
                {descripcion: 'SEDES', metrica: '4'},
                {descripcion: 'PROYECTOS EXITOSOS', metrica: '90'},
                {descripcion: 'COLABORADORES', metrica: '60'},
                {descripcion: 'AÑOS DE EXPERIENCIA', metrica: '6'},
            ],
            updateMetricas: false,
            clientes: [
                'clientes/alpargatas.png',
                'clientes/amadeus.png',
                'clientes/atos.png',
                'clientes/axa.png',
                'clientes/banco-comafi.png',
                'clientes/cjf-logo.png',
                'clientes/demag.png',
                'clientes/epson.png',
                'clientes/Flexz_logo-rgb-FINAL.png',
                'clientes/galicia-seguros-logo.png',
                'clientes/hidrovia.png',
                'clientes/indec-logo.png',
                'clientes/la-caja-logo.png',
                'clientes/larcgaming.png',
                'clientes/ldc.png',
                'clientes/lesaffre-vector-logo.png',
                'clientes/Logo-Gosmo.png',
                'clientes/Logo-InMotion.png',
                'clientes/logoScitum_MR.png',
                'clientes/meta4logo.png',
                'clientes/MM_FOTO-PERFIL-transparencia.png',
                'clientes/Nahuma_Logotipo_3.png',
                'clientes/omint-logo.png',
                'clientes/os-medife-logo.png',
                'clientes/reinamadrelogo.png',
                'clientes/richo-logo.png',
                'clientes/vilamoreschiclinic.png',
                'clientes/Walmart-logo-5.png',
                'clientes/WeWork_logo.png',
                'clientes/wizlynx-logo.png',
            ],
        }
    },
    mounted() {
        if (this.$route.hash) {
            window.scrollTo(0, document.querySelector(this.$route.hash).offsetTop)
        }
    },
    methods: {
        isMobile() {
            return Boolean(navigator.userAgent.toLocaleLowerCase().includes('mobile'))
        }
    }
}
</script>

<style lang="scss" scoped>
#desarrollando {
    margin-top: 100px;

    .service {
        img {
            //max-height: 200px;
            width: 100%;
        }

        &.streaming {
            img {
                max-height: 300px;
            }

            figcaption {
                padding: 2%;
            }
        }

        a {
            color: white;
            text-decoration: none;
            transition: .3s all ease;

            figure {
                &.ch__streaming {
                    position: relative;
                    z-index: 20;

                    &:before {
                        content: ' ';
                        display: block;
                        height: calc(100% + 10px);
                        width: calc(100% + 10px);
                        position: absolute;
                        bottom: -5px;
                        left: -5px;
                        z-index: -1;
                    }

                    &:hover {
                        &:before {
                            background: #1ff900;
                        }

                        figcaption {
                            background: #1ff900 !important;
                        }
                    }
                }

                figcaption {
                    background-color: black;
                    font-size: 2rem;
                    font-weight: bold;
                    padding: 5%;
                    text-align: left;
                    width: 100%;
                    transition: .3s all ease;
                }
            }

            &:hover {
                text-shadow: 0 0 15px #558ABB;

                figcaption {
                    background-color: #f28b1f;
                }
            }
        }
    }
}

#clientes {
    background-color: #000;
    color: #fff;
    padding: 70px 50px 150px;
    text-align: center;
}

.clientes {
    background: #f28b1f;
    height: 200px;
    margin: -100px auto 200px;
    overflow: hidden;
    width: 100%;
}

#metricas {
    background-color: #E62485;
    color: white;
    text-align: center;
    padding: 100px 0;

    .row .col:nth-child(-n+3) {
        border-right: 1px solid white;
    }
}

@media screen and (max-width: 600px) {
    .clientes {
        margin: -100px auto 100px;
    }
    #metricas {
        padding: 30px 0;
    }
}
</style>