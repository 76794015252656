<template>
    <div>
        <div class="container-fluid">
            <section id="software">
                <Title text="DESARROLLA TU SOFTWARE"></Title>
                <div class="servicios" id="proyectos">
                    <div>
                        <img src="@/assets/images/proyectos integrales.svg" alt="Análisis">
                    </div>
                    <div class="service">
                        <h3>PROYECTOS INTEGRALES</h3>
                        <p>Dirección y Transformación Digital orientada al negocio Equipos de alto rendimiento
                            multidisciplinarios</p>
                        <p>Metodologías de trabajo</p>
                        <p>Marketing digital</p>
                        <p>Dirección creativa</p>
                        <p>Procesos</p>
                        <p>Arquitectura y desarrollo de software</p>
                        <p>Infraestructura y seguridad</p>
                        <p>Mantenimiento, mejoras y evolución</p>
                    </div>
                </div>
                <div class="servicios right" id="desarrollo">
                    <div class="service">
                        <h3>DESARROLLAMOS A MEDIDA</h3>
                        <p>Desarrollo web</p>
                        <p>Desarrollo mobile</p>
                        <p>Múltiples tecnologias</p>
                        <p>Múltiples metodologias</p>
                        <p>Una sola visión: Ser su mejor solución</p>
                    </div>
                    <div>
                        <img src="@/assets/images/desarrollamos a medida.svg" alt="Análisis">
                    </div>
                </div>
                <div class="servicios" id="didid">
                    <div>
                        <img src="@/assets/images/DIDID.svg" alt="Análisis">
                    </div>
                    <div class="service">
                        <h3>DIDID</h3>
                        <p>Dirección </p>
                        <p>Investigación</p>
                        <p>Desarrollo</p>
                        <p>Innovación</p>
                        <p>Disrupción</p>
                    </div>
                </div>
            </section>
        </div>
        <ReturnButton></ReturnButton>
        <VideoSection></VideoSection>
    </div>
</template>

<script>
import Title from "../components/Title";
import VideoSection from "../components/VideoSection";

import ReturnButton from "../components/ReturnButton";
export default {
    name: "Software",
    components: {
        Title,
        VideoSection,
        ReturnButton
    },
    mounted() {
        window.addEventListener('scroll', () => {
            const proyectos = document.querySelector("#proyectos")
            const desarrollo = document.querySelector("#desarrollo")
            const didid = document.querySelector("#didid")

            if (proyectos && desarrollo && didid) {
                if (window.scrollY > (proyectos.offsetTop - 300) && window.scrollY < proyectos.offsetTop + 150) {
                    proyectos.classList.add('visible')
                    desarrollo.classList.remove('visible')
                    didid.classList.remove('visible')
                } else if (window.scrollY > (desarrollo.offsetTop - 400) && window.scrollY < desarrollo.offsetTop + 150) {
                    desarrollo.classList.add('visible')
                    proyectos.classList.remove('visible')
                    didid.classList.remove('visible')
                } else if (window.scrollY > (didid.offsetTop - 400) && window.scrollY < didid.offsetTop + 150) {
                    didid.classList.add('visible')
                    desarrollo.classList.remove('visible')
                    proyectos.classList.remove('visible')
                } else {
                    desarrollo.classList.remove('visible')
                    proyectos.classList.remove('visible')
                    didid.classList.remove('visible')
                }
            }
        })
    }
}
</script>

<style scoped lang="scss">
#software {
    margin: 50px 0 300px;
}
@media screen and (max-width: 600px) {
    #software {
        margin: 50px 0;
    }
}
</style>