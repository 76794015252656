<template>
    <div>
        <h2 :style="cssProps">{{ text }}</h2>
    </div>
</template>

<script>
export default {
    name: "Title",
    props: {
        text: {
            type: String,
            required: true
        }
    },
    computed: {
        cssProps() {
            return {
                '--content': `'${this.text}'`
            }
        }
    }
}
</script>

<style scoped lang="scss">
@-webkit-keyframes scale {
    0% {
        -webkit-transform: scale(.5);
        -ms-transform: scale(.5);
        transform: scale(.5);
        margin-top: 0;
    }

    75% {
        -webkit-transform: scale(3);
        -ms-transform: scale(3);
        transform: scale(3);
        margin-top: 50px;
    }

    100% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        margin-top: 50px;
    }
}

div{
    margin: 50px 0 0;
    min-height: 300px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding-top: 100px;
}

h2 {
    color: black;
    display: block;
    font-size: 5rem;
    text-align: center;
    font-weight: bold;
    position: relative;
    white-space: nowrap;
    content: var(--content);
    -webkit-animation: scale .8s ease-in-out;
    z-index: 9;

    &::before {
        content: var(--content);
        -webkit-text-stroke: 1px #a8a8a8;
        color: white;
        position: absolute;
        left: -50%;
        right: -50%;
        top: -165px;
        opacity: .4;
        z-index: -1;
        font-size: 20rem;
        white-space: nowrap;
        text-transform: lowercase;
    }
}

@media screen and (max-width: 800px){
    div{
        margin: 50px 0 0;
        min-height: 180px;
        padding-top: 20px;
    }

    h2{
        font-size: 2rem;

        &::before {
            font-size: 5rem;
            top: -40px;
        }
    }
}

@media screen and (max-width: 600px){
    div{
        margin: 50px 0 0;
        min-height: 80px;
    }

    h2{
        font-size: 1.3rem;
        &::before {
            font-size: 5rem;
            top: -40px;
        }
    }
}
</style>