<template>
    <div id="carrousel" @mouseenter="clearPlay" @mouseleave="autoPlay">
        <div class="prev" @click="prev">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-left-fill" viewBox="0 0 16 16">
                <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"/>
            </svg>
        </div>
        <div class="slide" v-for="(image, key) in images" :key="key" :style="`transform: translateX(-${page * 100}%)`">
            <img :src="require('../assets/images/'+image)" :alt="image">
        </div>
        <div class="next" @click="next">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: "CarrouselClientes",
    props: {
        images: {
            type: Array,
            required: true
        },
        auto: {
            type: Boolean,
            default: false
        },
        seconds: {
            default: 4
        }
    },
    data() {
        return {
            page: 0,
            interval: 0,
            showImg: 3
        }
    },
    computed: {
        imagesQty() {
            return this.images.length
        },
        totalPages() {
            const module = this.imagesQty % this.showImg

            return module === 0 ? this.imagesQty - this.showImg : this.imagesQty - module
        },
        milliseconds() {
            return parseInt(this.seconds) * 1000
        }
    },
    methods: {
        lastPage() {
            this.page = this.totalPages
        },
        firstPage(){
            this.page = 0
        },
        goToPage(page){
            this.page = page
        },
        prev() {
            this.page === 0 ? this.lastPage() : this.goToPage(this.page - this.showImg)
        },
        next() {
            this.page === this.totalPages ? this.firstPage() : this.goToPage(this.page + this.showImg)
        },
        autoPlay() {
            if (this.auto) {
                this.interval = setInterval(() => {
                    this.next()
                }, this.milliseconds)
            }
        },
        clearPlay() {
            clearInterval(this.interval)
        }
    },
    mounted() {
        this.autoPlay()
    }
}
</script>

<style scoped lang="scss">
#carrousel {
    align-items: center;
    display: flex;
    justify-content: start;
    height: 100%;
    width: 100%;
    position: relative;

    .slide {
        transition: all .5s ease-in;
        flex: 0 0 33.3333333%;
        padding: 0 10px;

        img {
            display: block;
            margin: auto;
            width: 70%;
            max-height: 200px;
            max-width: 200px;
            filter: grayscale(100%);
        }
    }

    .prev, .next {
        position: absolute;
        top: 0;
        height: 100%;
        transition: all .2s ease-in;
        width: 10%;
        z-index: 99;
        display: flex;
        align-items: center;
        color: #ffffff;

        &:hover {
            background: rgba(8, 8, 8, .2);
            cursor: pointer;

            svg{
                color: #ffffff;
            }
        }

        svg{
            color: rgba(255, 255, 255, .6);
            height: 30px;
            width: 30px;
        }
    }

    .prev {
        left: 0;
        padding-left: 10px;
        &:hover {
            box-shadow: 1px 0 18px 36px rgba(8, 8, 8, 0.2)
        }
    }

    .next {
        right: 0;
        justify-content: end;
        padding-right: 10px;

        &:hover {
            box-shadow: 1px 0 18px 36px rgba(8, 8, 8, 0.2)
        }
    }
}
</style>