<template>
    <div>
        <h3 class="metrica">{{ number }}</h3>
        <h6 class="descripcion">{{ metrica.descripcion }}</h6>
    </div>
</template>

<script>
export default {
    name: "Metrica",
    props: {
        metrica: {
            type: Object,
            required: true
        },
        update: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        update() {
            this.countNumbers()
        }
    },
    data() {
        return {
            count: false,
            num: 0,
            interval: 0
        }
    },
    mounted() {
        this.num = this.metrica.metrica
    },
    computed: {
        number() {
            return this.num
        }
    },
    methods: {
        countNumbers() {
            if (!this.count) {
                this.num = 1
                this.interval = setInterval(() => {
                    this.num++

                    if (this.num == this.metrica.metrica) {
                        clearInterval(this.interval)
                    }
                }, 500 / this.metrica.metrica)
                this.count = !this.count
            }
        }
    }
}
</script>

<style scoped>
.metrica {
    color: white;
    font-size: 6rem;
    font-weight: bold;
}

.descripcion {
    font-size: 1.5rem;
    font-weight: 300;
}

@media screen and (max-width: 600px){
    .metrica{
        font-size: 4rem;
        margin: 0;
    }

    .descripcion{
        font-size: 1.3rem;
        word-break: break-word;
    }
}
</style>