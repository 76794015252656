<template>
    <router-link :to="{name: 'Home'}" class="returnButton">Volver a servicios</router-link>
</template>

<script>
export default {
    name: "ReturnButton"
}
</script>

<style scoped lang="scss">
.returnButton{
    background: #000000;
    border-radius: 20px;
    color: #FFFFFF;
    display: block;
    margin: 50px auto;
    max-width: 200px;
    padding: 10px 20px;
    text-decoration: none;
    text-align: center;
    transition: .2s all ease-in;

    &:hover{
        box-shadow: 0 0 15px 2px #000000;
    }
}
</style>