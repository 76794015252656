<template>
    <div>
        <section id="campanas" class="container-fluid p-0">
            <Title text="CAMPAÑAS Y ANALYTICS"></Title>
            <div class="servicios" id="analisis">
                <div><img src="@/assets/images/Analisis.svg" alt="Análisis"></div>
                <div class="service">
                    <h3>ANALISIS</h3>
                    <p>Análisis interno</p>
                    <p>Análisis del mercado</p>
                    <p>Business model canvas</p>
                    <p>Objetivos SMART</p>
                    <p>Análisis de la competencia</p>
                    <p>Big Data Machine</p>
                </div>
            </div>
            <div class="servicios right" id="estrategia">
                <div class="service">
                    <h3>ESTRATEGIA DE COMUNICACIÓN</h3>
                    <p>Generación del plan de comunicación y Marketing</p>
                    <p>FODA</p>
                    <p>Fijar objetivo</p>
                    <p>Definición del buyer persona</p>
                    <p>Calendarización</p>
                    <p>Mensaje</p>
                    <p>Canales de comunicación</p>
                    <p>Social Media</p>
                    <p>Evaluación de los resultados</p>
                    <p>Mantenimiento, mejoras y evolución</p>
                </div>
                <div><img src="@/assets/images/estrategia de comunicacionSVG.svg" alt="Estrategia"></div>
            </div>
            <div class="servicios" id="informacion">
                <div><img src="@/assets/images/potencia tu informacionSVG.svg" alt="Información"></div>
                <div class="service">
                    <h3>POTENCIA TU INFORMACIÓN</h3>
                    <p>Google marketing platform</p>
                    <p>Google DV 360</p>
                    <p>Facebook ADS</p>
                    <p>Big data intelligence</p>
                </div>
            </div>
        </section>
        <ReturnButton></ReturnButton>
        <VideoSection></VideoSection>
    </div>
</template>

<script>
import Title from "../components/Title";
import VideoSection from "../components/VideoSection";
import ReturnButton from "../components/ReturnButton";

export default {
    name: "Campanas",
    components: {
        Title,
        VideoSection,
        ReturnButton
    },
    data() {
        return {
            visible: ''
        }
    },
    mounted() {
        window.addEventListener('scroll', () => {
            const analisis = document.querySelector("#analisis")
            const estrategia = document.querySelector("#estrategia")
            const informacion = document.querySelector("#informacion")

            if (analisis && estrategia && informacion && window.innerWidth >= 600) {
                if (window.scrollY > (analisis.offsetTop - 300) && window.scrollY < analisis.offsetTop + 150) {
                    analisis.classList.add('visible')
                    informacion.classList.remove('visible')
                    estrategia.classList.remove('visible')
                } else if (window.scrollY > (estrategia.offsetTop - 400) && window.scrollY < estrategia.offsetTop + 150) {
                    estrategia.classList.add('visible')
                    analisis.classList.remove('visible')
                    informacion.classList.remove('visible')
                } else if (window.scrollY > (informacion.offsetTop - 500) && window.scrollY < informacion.offsetTop + 150) {
                    informacion.classList.add('visible')
                    analisis.classList.remove('visible')
                    estrategia.classList.remove('visible')
                } else {
                    analisis.classList.remove('visible')
                    informacion.classList.remove('visible')
                    estrategia.classList.remove('visible')
                }
            }
        })
    }
}
</script>

<style scoped lang="scss">
#campanas {
    margin: 50px 0 300px;
}

@media screen and (max-width: 600px) {
    #campanas {
        margin: 50px 0;
    }
}
</style>