<template>
    <div>
        <div class="container-fluid">
            <section id="identidad">
                <Title text="POTENCIA TU IDENTIDAD"></Title>
                <div class="servicios" id="marca">
                    <div>
                        <img src="@/assets/images/desarrollamos tu marca.svg" alt="Análisis">
                    </div>
                    <div class="service">
                        <h3>DESARROLLAMOS TU MARCA</h3>
                        <p>Dirección Creativa</p>
                        <p>Creación de identidad (institucional y personal)</p>
                        <p>MVP (Minimum Viable Product)</p>
                        <p>Manual de marca</p>
                        <p>KV (Key Visual)</p>
                        <p>Diseño gráfico y web</p>
                        <p>Diseño y edición audiovisual</p>
                        <p> Lanzamiento al mercado</p>
                        <p>Mantenimiento, mejoras y evolución</p>
                    </div>
                </div>
                <div class="servicios right" id="producer">
                    <div class="service">
                        <h3>CH PRODUCER</h3>
                        <p>Dirección de arte</p>
                        <p>Realización, producción y edición de videos y fotografía</p>
                        <p>Animación 2d / 3d / motion graphics</p>
                        <p>Producción de audio y música</p>
                        <p>Filmaciones drone / 360 /</p>
                    </div>
                    <div>
                        <img src="@/assets/images/ch producer.svg" alt="Análisis">
                    </div>
                </div>
            </section>
        </div>
        <ReturnButton></ReturnButton>
        <VideoSection></VideoSection>
    </div>
</template>

<script>
import Title from "../components/Title";
import VideoSection from "../components/VideoSection";
import ReturnButton from "../components/ReturnButton";

export default {
    name: "Identidad",
    components: {
        Title,
        VideoSection,
        ReturnButton
    },
    mounted() {
        window.addEventListener('scroll', () => {
            const marca = document.querySelector("#marca")
            const producer = document.querySelector("#producer")
            if (marca && producer) {
                if (window.scrollY > (marca.offsetTop - 300) && window.scrollY < marca.offsetTop + 150) {
                    marca.classList.add('visible')
                    producer.classList.remove('visible')
                } else if (window.scrollY > (producer.offsetTop - 400) && window.scrollY < producer.offsetTop + 150) {
                    marca.classList.remove('visible')
                    producer.classList.add('visible')
                } else {
                    marca.classList.remove('visible')
                    producer.classList.remove('visible')
                }
            }

        })
    }
}
</script>

<style scoped lang="scss">
#identidad {
    margin: 50px 0 300px;
}

@media screen and (max-width: 600px) {
    #identidad {
        margin: 50px 0;
    }
}
</style>